.module-navigation {
    background-color: white;
    height: var(--nav-height);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

#nav-site-main {
    align-items: stretch;
    background-color: white;
    display: grid;
    grid-template-columns: 9.90125rem auto min-content min-content;
    padding-left: var(--block-space-horizontal);
    position: relative;
    width: 100%;
    z-index: 101;
}

#nav-site-main>nav {
    display: flex;
    flex-direction: row;
    padding-left: 2rem;
}

.module-navigation a {
    padding: 0 1.5rem;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.module-navigation .active,
.module-navigation .current,
.module-navigation nav a:hover {
    color: var(--color-yellow);
}

.module-navigation .logo {
    padding: 0;
}

.module-navigation .logo img {
    height: 3.5rem
}

.module-navigation form {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.module-navigation form input {
    background-color: white;
    border: none;
    box-shadow: -2rem 0 .5rem rgba(255, 255, 255, 1);
    font-size: 1.3125rem;
    height: var(--nav-height);
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity, left 500ms ease-out;
    width:1440px; /* px for js */
    z-index: 103;
}

.module-navigation form input:focus {
    opacity: 1;
    outline: none;
    transition: opacity, left 500ms ease-out
}

@media (min-width: 640px) {
  .module-navigation form input:focus {

      left: -20rem;

  }
}

.module-navigation form input:focus+div {
    color: var(--color-yellow)
}

.module-navigation form div {
    height: var(--nav-height);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 105;
    position: relative;
    top: -.1rem;
    width: 3.5rem;
}

.module-navigation form svg {
    cursor: pointer;
    width: 1.25rem;
}

.module-navigation form button {
    display: none
}

#donate-link {
    background-color: var(--color-yellow);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 105
}

#donate-link:hover {
    color: inherit;
}

#nav-site-button {
    z-index: 105;
    position: relative;
    padding: 0 var(--block-space-horizontal) 0 0;
    background: white
}

#nav-site-button.show #openmobilenav, #nav-site-button #closemobilenav {
    display: none
}

#nav-site-button.show #closemobilenav {
    display: inherit;
}

@media (min-width: 1000px) {
    #nav-site-button {
        display: none;
    }
}

@media (max-width: 999px) {
    #donate-link, #nav-site-main>nav a {
        display: none;
    }
}

#subnav {
    position: sticky;
    background-color: white;
    padding: 0 var(--block-space-horizontal);
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
    height: 0;
    overflow: hidden;
    transition: height .5s ease-in-out;
}

#subnav.show, #subnav:hover {
    height: fit-content;
    height: 20rem
}


#subnav>nav {
    max-width: var(--width-main);
    padding: 5rem var(--block-space-horizontal);
}
#subnav h3 {
  margin-bottom: 1.75rem;
}

#subnav>nav a {
    padding: 0;
    justify-self: flex-start;
}

#subnav nav nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: column;
    gap: .625rem;
}

@media (min-width: 999px) {
    #mobilenav {
        display: none !important
    }
}

@media (max-width: 1000px) {
    .module-navigation #subnav {
        display: none !important
    }

    #mobilenav {
        background-color: white;
        grid-template-columns: 1fr;
        height: 100vh;
        overflow: scroll;
        padding-top:3.125rem;
        padding-bottom: 5rem;
        position: absolute;
        right: 0;
        top: var(--nav-height);
        transition: width .5s ease-in-out;
        width: 0;
        z-index:110;
    }

    #mobilenav.show {
        width: 100%;
    }

    #mobilenav a {
        display: inherit;
        white-space: nowrap;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }

    #mobilenav>a {
        font-size: 1.5rem;
    }

    #mobilenav>nav.show {
        display: grid;
        grid-template-columns: 1fr;
    }

    #mobilenav nav {
        padding-bottom: 2rem
    }

    #mobilenav nav:last-child {
        margin-bottom:10rem;
    }

    #mobilenav nav a {
        font-size: 1.125rem;
    }
}
